import IconHinweis from "../svgs/IconHinweis";

interface PropsWarnung {
  title?: string;
  message: string;
  marginBottom?: boolean;
}

export function Warnung(options: PropsWarnung) {
  return (
    <>
      <div
        className={
          options.marginBottom
            ? "alert alert-box alert-info border-0 rounded-0 mb-5"
            : "alert alert-box alert-info border-0 rounded-0"
        }
        role="alert"
        data-gtm-box="Hinweis"
        style={{ backgroundColor: "var(--bs-warning)" }}
      >
        <div className="alert-info-bulb">
          <IconHinweis style={{ width: "2.5rem", height: "2.5rem", stroke: "#000" }} />
          <span>Hinweis</span>
        </div>
        <div className="alert-info-content">
          {options.title && <p className="title">{options.title}</p>}
          <p>{options.message} </p>
        </div>
      </div>
    </>
  );
}
