export const URL_HOME = "home";
export const URL_LOGOUT = "logout";
export const URL_STEWARD_LOGOUT = "steward_logout";
export const URL_IDP_LOGOUT = "idp_logout";
export const URL_NACHRICHTEN = "nachrichten";
export const URL_ALLE_ESERVICES = "alle_eservices";
export const URL_FIRMEN_AZ = "firmenAZ";
export const URL_BENUTZERKONTO = "benutzerkonto";
export const URL_BENUTZERKONTO_IDENTITY = "benutzerkonto_identity";
export const URL_WKIS_STATE_API = "wkis_state_api";

export function getLink(key: string): string {
  if (window.location.hostname.indexOf("qss") >= 0) {
    switch (key) {
      case URL_HOME:
        return "https://mitgliedschaft.qss.wko.at";
      case URL_LOGOUT:
        return "https://mitgliedschaft.qss.wko.at/MeinWKOREST/b/logout/mitgliedschaft";
      case URL_IDP_LOGOUT:
        return "https://blueqss01.oe.wknet/stewardPortal/samlLogout?idplogout=true&redirectUrlForSteward=https://mitgliedschaft.qss.wko.at/MeinWKOREST/b/logout/mitgliedschaft";
      case URL_STEWARD_LOGOUT:
        return "https://mitgliedschaft.qss.wko.at/MeinWKOREST/b/logout/steward/mitgliedschaft";
      case URL_NACHRICHTEN:
        return "https://qss.wko.at/nachrichten";
      case URL_ALLE_ESERVICES:
        return "https://qss.wko.at/eservices";
      case URL_FIRMEN_AZ:
        return "https://firmen.qss.wko.at/Wartung/";
      case URL_BENUTZERKONTO:
        return "https://benutzerkonto.qss.wko.at/";
      case URL_BENUTZERKONTO_IDENTITY:
        return "https://benutzerkonto.qss.wko.at/Identity";
      case URL_WKIS_STATE_API:
        return "https://berechtigungen.qss.wko.at/api/State";
    }
  } else {
    switch (key) {
      case URL_HOME:
        return "https://mitgliedschaft.wko.at";
      case URL_LOGOUT:
        return "https://mitgliedschaft.wko.at/MeinWKOREST/b/logout/mitgliedschaft";
      case URL_IDP_LOGOUT:
        return "https://eservice.jungewirtschaft.at/stewardPortal/samlLogout?idplogout=true&redirectUrlForSteward=https://mitgliedschaft.wko.at/MeinWKOREST/b/logout/mitgliedschaft";
      case URL_STEWARD_LOGOUT:
        return "https://mitgliedschaft.wko.at/MeinWKOREST/b/logout/steward/mitgliedschaft";
      case URL_NACHRICHTEN:
        return "https://wko.at/nachrichten";
      case URL_ALLE_ESERVICES:
        return "https://wko.at/eservices";

      case URL_FIRMEN_AZ:
        return "https://firmen.wko.at/Wartung/";
      case URL_BENUTZERKONTO:
        return "https://benutzerkonto.wko.at/";
      case URL_BENUTZERKONTO_IDENTITY:
        return "https://benutzerkonto.wko.at/Identity";
      case URL_WKIS_STATE_API:
        return "https://berechtigungen.wko.at/api/State";
    }
  }
  return "";
}
